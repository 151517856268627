import { AxiosResponse } from 'axios'
import {
    SubjectAction,
    LabelAction,
    IsLoginAction,
    UserInfoAction,
    SetSubjectIdAction,
    GetUserInfoAction,
    CheckLoginAction,
    GetApplysAction,
    LoginPopAction,
} from '@/store/actions/index.ts'
import { Paper, Label, UserInfo, Apply, StudentApply } from '@/@types/index.ts'
import { Subject } from '@/@types/subjects.ts'

export const labelsReducer = (state = [], action: LabelAction): Label[] => {
    switch (action.type) {
        case 'GET_LABELS_SUCCESS':
            if (action.payload) {
                const payload = action.payload as AxiosResponse<Label[]>
                return payload.data
                    .filter((item) => item.paper_count > 0)
                    .map((first) => {
                        return {
                            ...first,
                            children: first.children
                                .filter((item) => item.paper_count > 0)
                                .map((second) => ({
                                    ...second,
                                    children: second.children
                                        .filter((item) => item.paper_count > 0)
                                        .map((third) => ({
                                            ...third,
                                            children: [],
                                        })),
                                })),
                        }
                    })
            }
            return []
        case 'GET_LABELS_FAIL':
        default:
            return state
    }
}

export const subjectReducer = (
    state = [],
    action: SubjectAction
): Subject[] => {
    switch (action.type) {
        case 'GET_SUBJECT_SUCCESS':
            if (action.payload) {
                const payload = action.payload as AxiosResponse<Subject[]>
                return payload.data
            }
            return []
        case 'GET_SUBJECT_FAIL':
        default:
            return state
    }
}

export const setSubjectIdReducer = (
    state = 1,
    action: SetSubjectIdAction
): number => {
    if (action.type === 'SET_SUBJECT_ID') {
    return action.value}
    return state
}

export interface PapersResponse {
    teacher_practice_papers: Paper[]
    total: number
}
interface PaperAction {
    type: string
    payload?: AxiosResponse<PapersResponse>
}

export const papersReducer = (
    state = {
        teacher_practice_papers: [],
        total: 0,
    },
    action: PaperAction
): PapersResponse => {
    switch (action.type) {
        case 'GET_PAPERS_SUCCESS':
            return action.payload?.data || state
        case 'GET_PAPERS_FAIL':
        default:
            return state
    }
}

export const isLogin = (
    state = false,
    action: IsLoginAction & CheckLoginAction
): boolean => {
    switch (action.type) {
        case 'SET_IS_LOGIN':
            return action.value || false
        case 'CHECK_LOGIN_SUCCESS':
            if (action.payload) {
                const payload = action.payload as AxiosResponse<UserInfo>
                return !!payload.data
            }
            return false
        default:
            return state
    }
}

export const userInfo = (
    state = {},
    action: UserInfoAction & GetUserInfoAction
): object => {
    switch (action.type) {
        case 'SET_USER_INFO':
            return action.value || state
        case 'GET_USER_INFO_SUCCESS':
            if (action.payload) {
                const payload = action.payload as AxiosResponse<UserInfo>
                return payload.data
            }
            return state
        default:
            return state
    }
}

export const getApplysReducer = (
    state = [],
    action: GetApplysAction
): Apply[] => {
    switch (action.type) {
        case 'GET_APPLY_SUCCESS':
            if (action.payload) {
                const payload = action.payload as AxiosResponse<Apply[]>
                payload.data.forEach((item) => {
                    if (item && item.student_apply) {
                        item.student_apply.forEach((student: StudentApply) => {
                            Object.assign(student, {
                                isAgree: false,
                            })
                        })
                    }
                })
                return payload.data
            }
            return state
        case 'SET_APPLY':
            return action.value as Apply[]
        default:
            return state
    }
}

export const isShowLoginPopReducer = (
    state = false,
    action: LoginPopAction
): boolean => {
    switch (action.type) {
        case 'SET_LOGIN_POP':
            return action.value
        default:
            return state
    }
}
