import React from 'react'

import bannerImg from '@/assets/images/home/banner-text.png'
import bannerVideo from '@/assets/images/home/banner-video.png'
import bannerPlay from '@/assets/images/home/banner-play.png'

import knowledgeScreenshot from '@/assets/images/home/knowledge-screenshot.png'
import testScreenshot from '@/assets/images/home/test-screenshot.png'
import moocScreenshot from '@/assets/images/home/mooc-screenshot.png'
import gaokaoScreenshot from '@/assets/images/home/gaokao-screenshot.png'
import knowledgeIcon from '@/assets/images/home/knowledge-icon.png'
import testIcon from '@/assets/images/home/test-icon.png'
import moocIcon from '@/assets/images/home/mooc-icon.png'
import gaokaoIcon from '@/assets/images/home/gaokao-icon.png'
import onlineIcon from '@/assets/images/home/online-icon.png'
import offlineIcon from '@/assets/images/home/offline-icon.png'
import onlinePublish from '@/assets/images/home/online-publish.png'
import offlinePublish from '@/assets/images/home/offline-publish.png'

import { Link } from 'react-router-dom'
import { Button, Tabs } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import Flow from '@/views/Home/components/Flow.tsx'
import Team from '@/views/Home/components/Team.tsx'
import Schools from '@/views/Home/components/Schools.tsx'
import Products from '@/views/Home/components/Products.tsx'
import styles from './index.module.scss'

const { TabPane } = Tabs
const Home: React.FC = () => {
    return (
        <div className={styles.homepage}>
            <Banner />
            <Knowledge />
            <Publish />
            <VipApply />
            <Flow />
            <Team />
            <Schools />
            <Products />
        </div>
    )
}
// Banner
const Banner: React.FC = () => {
    return (
        <div className={styles['home-banner']}>
            <div className={styles['banner-container']}>
                <div className={styles['banner-info']}>
                    <img src={bannerImg} alt="" />
                </div>
                <div className={styles['banner-video']}>
                    <img
                        className={styles['video-bg']}
                        src={bannerVideo}
                        alt=""
                    />
                    <img
                        className={styles['play-btn']}
                        src={bannerPlay}
                        alt=""
                    />
                </div>
            </div>
        </div>
    )
}
// Knowledge
const Knowledge: React.FC = () => {
    const list = [
        {
            id: '1',
            title: '知识点专题卷',
            icon: knowledgeIcon,
            img: knowledgeScreenshot,
            content:
                '各科精选习题，按照知识点结构精心组卷，一套卷解决一个专题。针对不同的学习进度，匹配不同难度的作业练习，由简入难，逐个突破考点。',
            url: '/knowledge/topic',
        },
        {
            id: '2',
            icon: testIcon,
            img: testScreenshot,
            title: '章节测试卷',
            content:
                '针对章节知识点组成的试卷，综合性更强，适用于月考、期中、期末考试的复习与备考，每道题配套视频讲解，帮助学生构建完整的知识体系。',
            url: '/knowledge/chapter',
        },
        {
            id: '3',
            icon: gaokaoIcon,
            img: gaokaoScreenshot,
            title: '高考真题卷',
            content:
                '精选历年高考真题，配有完整详细的文字与视频讲解，帮助学生掌握高考命题思路，了解高考思维模式。',
            url: '/custom?type=3',
        },
        {
            id: '4',
            icon: moocIcon,
            img: moocScreenshot,
            title: '高考模拟卷',
            content:
                '精选最新的各省市高考模拟、联考精品试卷，紧跟新式题型、知识点考法，通过配套的视频讲解让学生更熟悉高考题型。',
            url: '/custom?type=4',
        },
    ]
    return (
        <div className={styles['home-knowledge']}>
            <title className={styles['knowledge-title']}>
                多样化组卷 高效教学
            </title>
            <Tabs className="knowledge-tab" defaultActiveKey="1" centered>
                {list.map((item) => (
                    <TabPane
                        tab={
                            <div>
                                <img
                                    className="knowledge-tab-icon"
                                    src={item.icon}
                                    alt=""
                                />
                                {item.title}
                            </div>
                        }
                        key={item.id}
                    >
                        <div className={styles['knowledge-container']}>
                            <div className={styles.info}>
                                <title className={styles.bg}>
                                    {item.title}
                                </title>
                                <p className={`${styles['info-content']}`}>
                                    {item.content}
                                </p>
                                <Link to={item.url}>
                                    <Button
                                        type="primary"
                                        className="green-button"
                                    >
                                        立即体验
                                        <RightOutlined />
                                    </Button>
                                </Link>
                            </div>
                            <div className={styles.screenshot}>
                                <img src={item.img} alt="" />
                            </div>
                        </div>
                    </TabPane>
                ))}
            </Tabs>
        </div>
    )
}

// 如何布置作业
const Publish: React.FC = () => {
    const publishList = [
        {
            id: '1',
            title: '线下布置作业',
            icon: offlineIcon,
            img: offlinePublish,
        },
        {
            id: '2',
            title: '在线收发作业',
            icon: onlineIcon,
            img: onlinePublish,
        },
    ]
    return (
        <div className={styles['home-publish']}>
            <title className={styles['publish-title']}>老师如何布置作业</title>
            <Tabs defaultActiveKey="1" centered>
                {publishList.map((item) => (
                    <TabPane
                        tab={
                            <div>
                                <img
                                    className="publish-tab-icon"
                                    src={item.icon}
                                    alt=""
                                />
                                {item.title}
                            </div>
                        }
                        key={item.id}
                    >
                        <img
                            className={styles['publish-img']}
                            src={item.img}
                            alt=""
                        />
                    </TabPane>
                ))}
            </Tabs>
        </div>
    )
}
// vipApply
const VipApply: React.FC = () => {
    return (
        <Link to="/try">
            <div className={`${styles['home-vip']} pointer`} />
        </Link>
    )
}
export default Home
