export const showName = (
    id: number,
    list: any[],
    key = 'id',
    show = 'name'
): string => {
    const res = list.find((item) => item[key] === id)
    if (res) return res[show]
    return '未知'
}

export const downloadFile = (blob: Blob, paperName: string): void => {
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.setAttribute('download', paperName)
    a.click()
    setTimeout(() => {
        URL.revokeObjectURL(url)
    }, 200)
}

export function debounce<T extends Function>(cb: T, wait = 1000): Function {
    let h: any = 0
    const callable = (...args: any) => {
        clearTimeout(h)
        h = setTimeout(() => cb(...args), wait)
    }
    return (callable as any) as T
}
