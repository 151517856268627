import React from 'react'
import ReactDOM from 'react-dom'
// redux相关
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import { createLogger } from 'redux-logger'
// import promiseMiddleware from 'redux-promise-middleware';
import axiosMiddleware from 'redux-axios-middleware'

import axiosClient from '@/utils/request.ts'
import reducer from '@/store/reducers/index.ts'
import { getSubjects, getUserInfo, checkLogin } from '@/store/actions/index.ts'

import 'antd/dist/antd.less'
import '@/assets/styles/normalize.css'
import './index.less'
// 项目APP
import { BrowserRouter as Router } from 'react-router-dom'
import App from '@/App.tsx'

// import * as serviceWorker from '@/serviceWorker.ts'

const loggerMiddleware = createLogger()

const store = createStore(
    reducer,
    applyMiddleware(
        loggerMiddleware,
        // promiseMiddleware
        axiosMiddleware(axiosClient)
    )
)
store.dispatch(checkLogin())
store.dispatch(getSubjects())
store.dispatch(getUserInfo())

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <App />
        </Router>
    </Provider>,
    document.getElementById('root')
)
setInterval(() => {
    store.dispatch(checkLogin())
}, 1000 * 60 * 5)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
