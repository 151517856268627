import React, { useEffect, useState, Suspense } from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import routes from '@/router/index.ts'
import Header from '@/views/Common/Header.tsx'
import Footer from '@/views/Common/Footer.tsx'
import LoginPop from '@/views/Common/LoginPop.tsx'
import styles from './App.module.scss'

const notShows = [/^\/login/, /^\/register/, /^\/download/, /^\/poster/, /^\/train\/download/, /^\/practiceDownload/, /^\/commonDownload/]

const Loading = (
    <div className={styles.loading}>
        <Spin
            tip="加载中……"
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        />
    </div>
)

const App: React.FC = () => {
    const location = useLocation()
    const [isShowLayer, setIsShowLayer] = useState(true)

    useEffect(() => {
        const { pathname } = location
        setIsShowLayer(!notShows.some((reg) => reg.test(pathname)))
    }, [location])

    // TODO: 懒加载失败时候的展示
    return (
        <div
            className={styles.App}
            style={{ paddingBottom: isShowLayer ? '285px' : '0' }}
        >
            {isShowLayer && <Header />}
            <section className={styles.content}>
                <Suspense fallback={Loading}>
                    <Switch>
                        <Redirect exact from="/" to="/home" />
                        {routes.map((route) => (
                            <Route key={route.path} path={route.path}>
                                <route.component
                                    redirect={route.redirect}
                                    routes={route.routes}
                                />
                            </Route>
                        ))}
                    </Switch>
                </Suspense>
            </section>
            {isShowLayer && <Footer />}
            <LoginPop />
        </div>
    )
}
export default App
