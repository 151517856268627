import React from 'react'
import styles from './Schools.module.scss'

const Schools: React.FC = () => {
    const list = [
        [
            '山西应县一中',
            '山西大同实验中学',
            '山西大同三中',
            '山西大同北师大附中',
            '山西大同一中',
            '山西临汾一中',
        ],
        [
            '安徽成效中学',
            '安徽太和一中',
            '安徽太和中学',
            '河南鹿邑一中',
            '河南新乡原阳实验高中',
            '河南焦作博爱一中',
        ],
        [
            '湖北武汉开发区一中',
            '湖北武汉建港中学',
            '深圳光明高级中学',
            '深圳科学高中',
            '深圳第二实验中学',
            '深圳第三高级中学',
        ],
        [
            '四川德阳五中',
            '四川德阳外国语学校',
            '陕西西安高陵一中',
            '安徽阜阳一中',
            '安徽阜阳二中',
            '湖北黄石二中 ',
        ],
        [
            '亳州启明实验高中',
            '河南许昌禹州一高',
            '云南腾冲第一中学',
            '安徽太和一中',
            '山西长治沁县六中',
            '重庆梁平一中',
        ],
        [
            '阜阳成效中学',
            '吉林油田高级中学',
            '合肥皖智中学',
            '陕西延安中学',
            '贵阳乌当中学',
            '安徽蚌埠二中',
        ],
    ]
    const listItems = list.map((row, rowIndex) => {
        const listSchools = row.map((school) => {
            return <li key={school}>{school}</li>
        })
        return (
            <ul key={row.toString()} className={styles[`opacity${rowIndex}`]}>
                {listSchools}
            </ul>
        )
    })
    return (
        <div className={`${styles['home-schools']}`}>
            <div className="show-box">
                <title>合作学校案例</title>
                <title className={styles.sub}>
                    清北道远课堂已与全国百余所高中联合，提升学校清北人数，985、211达线率
                </title>
                <div className={styles['schools-container']}>{listItems}</div>
            </div>
        </div>
    )
}

export default Schools
