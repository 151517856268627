import React from 'react'
import OopImg from '@/assets/images/common/404.png'

const noMatch: React.FC = () => {
    return (
        <div>
            <img src={OopImg} alt="" />
        </div>
    )
}

export default noMatch
