import axios, {
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
    AxiosError,
    // Canceler,
} from 'axios'
import qs from 'qs'

/* 防止重复提交，利用axios的cancelToken */
// let pending: string[] = []
// const { CancelToken } = axios

// const removePending = (config: AxiosRequestConfig, cancel?: Canceler): void => {
//     if (!config.url) return

//     if (pending.indexOf(config.url) !== -1) {
//         if (cancel) {
//             cancel('取消重复请求')
//         } else {
//             pending.splice(pending.indexOf(config.url), 1)
//         }
//     } else {
//         pending.push(config.url)
//     }
// }

/* 创建axios实例 */
const service: AxiosInstance = axios.create({
    baseURL: window.location.origin,
    paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
    },
    timeout: 50000,
})
export const authRequest: AxiosInstance = axios.create({
    baseURL: window.location.origin,
    headers: {
        Authorization: 'f215c150-fea7-11e8-bcfe-bb5bccd716b6'
    },
    paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
    },
    timeout: 50000,
})
service.interceptors.request.use(
    (requestConfig: AxiosRequestConfig) => {
        const config = { ...requestConfig }
        // config.cancelToken = new CancelToken((cancel: Canceler) => {
        //     removePending(config, cancel)
        // })
        return config
    },
    (error: AxiosError) => {
        Promise.reject(error)
    }
)

service.interceptors.response.use(
    (response: AxiosResponse) => {
        // removePending(response.config)
        if (response.status > 299) {
            throw response
        }
        return response
    },
    (error: AxiosError) => {
        // 异常处理
        // pending = []
        if (error.message === '取消重复请求') {
            return Promise.reject(error)
        }
        return Promise.reject(error)
    }
)

export default service
