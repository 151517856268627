const isProd = process.env.NODE_ENV === 'production'

export const CONFIG = {
    TencentCaptchaAppId: isProd ? '2064456562' : '2062062665',
}

export const REGEXP = {
    mobile: /^(\+?0?86?)?1[3456789]\d{9}$/,
}

export const PRACTICE_OPTIONS = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
]

export default {
    CONFIG,
    REGEXP,
    PRACTICE_OPTIONS,
}
