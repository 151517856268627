import React from 'react'
import logoImg from '@/assets/images/common/masterLogo.png'
import { Popover } from 'antd'
import { EnvironmentFilled, PhoneFilled } from '@ant-design/icons'
import styles from './Footer.module.scss'

interface RequireContext {
    keys(): string[]
    <T>(id: string): T
    resolve(id: string): string
    id: string
}

const context: RequireContext = require.context(
    '@/assets/images/footer',
    false,
    /\.(png|jpg|jpeg)$/
)
interface MapType {
    [_: string]: string
}
interface PromotionItem {
    label: string
    img: string
    qrList: PromotionSubItem[]
}
interface PromotionSubItem {
    subLabel: string
    img: string
}
interface PromotionPopverProps {
    list: PromotionSubItem[]
}
const imgMap: MapType = {}
context.keys().forEach((item: string): void => {
    const key: string[] = /.\/(.+?)\.(png|jpg|jpeg)/g.exec(item) as string[]
    imgMap[key[1]] = context(item)
})

const Footer: React.FC = () => {
    // 推广图标二维码
    const promotionLists: PromotionItem[] = [
        {
            label: '公众号',
            img: imgMap.weixin,
            qrList: [
                {
                    subLabel: '道远课堂',
                    img: imgMap.wx_pub_dykt,
                },
                {
                    subLabel: '清北学霸',
                    img: imgMap.wx_pub_qbxb,
                },
                {
                    subLabel: '诗酒语文',
                    img: imgMap.wx_pub_sjyw,
                },
            ],
        },
        {
            label: '抖音',
            img: imgMap.douyin,
            qrList: [
                {
                    subLabel: '道远课堂',
                    img: imgMap.dykt_douyin_qr,
                },
                {
                    subLabel: '学霸请就位',
                    img: imgMap.dykt_douyin_xb_qr,
                },
            ],
        },
        {
            label: '哔哩哔哩',
            img: imgMap.bilibili,
            qrList: [
                {
                    subLabel: '道远课堂',
                    img: imgMap.dykt_bili_qr,
                },
            ],
        },
        {
            label: '微博',
            img: imgMap.weibo,
            qrList: [
                {
                    subLabel: '道远课堂',
                    img: imgMap.dykt_weibo_qr,
                },
            ],
        },
    ]
    // 应用图标二维码
    const appList: PromotionItem[] = [
        {
            label: '道远课堂',
            img: imgMap.dykt,
            qrList: [{ subLabel: '微信扫码下载', img: imgMap.dykt_qr }],
        },
        {
            label: '道远作业',
            img: imgMap.dyzyapp,
            qrList: [{ subLabel: '微信扫码下载', img: imgMap.dy_zuoye_qr }],
        },
        {
            label: '道远题库',
            img: imgMap.dytk,
            qrList: [{ subLabel: '微信扫码下载', img: imgMap.dytk_qr }],
        },
        {
            label: '道远志愿',
            img: imgMap.dyzy,
            qrList: [{ subLabel: '微信扫码下载', img: imgMap.dyzy_qr }],
        },
    ]
    return (
        <footer id={styles.footer}>
            <div className={`${styles['app-footer']}`}>
                <div className={`${styles['footer-contents']}`}>
                    <div className={styles['footer-about']}>
                        <div className={styles.logo}>
                            <img src={logoImg} alt="" />
                        </div>
                        <div className={styles.intro}>
                            北京道远教育科技有限公司是优质教育资源的制作提供方和分享者。道远教育汇集了全国一线名师和北大清华的优秀学子，精心打造互联网教学产品和服务，为全国各地的学校、教育机构和家长客户提供创新的互联网教学产品和服务，让全国各地的学生能够更公平的接触好的教育内容和教育资源。
                        </div>
                    </div>
                    <div className={styles['footer-contact']}>
                        <div className={styles.title}>联系我们</div>
                        <div className={styles.mobile}>
                            <PhoneFilled />
                            <div>400-996-3958</div>
                        </div>
                        <div className={styles.mobile}>
                            <EnvironmentFilled />
                            <div>地址：北京市海淀区颐和园路100号</div>
                        </div>
                        <div className={styles.promotion}>
                            {promotionLists.map((item) => (
                                <div
                                    className={styles['promotion-item']}
                                    key={item.label}
                                >
                                    <Popover
                                        content={
                                            <PromotionPopver
                                                list={item.qrList}
                                            />
                                        }
                                        trigger="hover"
                                        overlayClassName="footer-promotion-popver"
                                    >
                                        <img src={item.img} alt="promotion" />
                                    </Popover>
                                    <div>{item.label}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={styles['footer-apps']}>
                        <div className={styles.title}>道远课堂旗下应用下载</div>
                        <div className={styles['apps-list']}>
                            {appList.map((item) => (
                                <div
                                    className={styles['apps-list-item']}
                                    key={item.label}
                                >
                                    <Popover
                                        content={
                                            <PromotionPopver
                                                list={item.qrList}
                                            />
                                        }
                                        trigger="hover"
                                        overlayClassName="footer-promotion-popver"
                                    >
                                        <img src={item.img} alt="promotion" />
                                    </Popover>
                                    <div>{item.label}</div>
                                </div>
                            ))}
                        </div>
                        <div className={styles.more}>
                            更多优秀产品敬请期待...
                        </div>
                    </div>
                </div>
                <div className={`${styles.copyright}`}>
                    反盗版和反盗链权利声明copyright © 北京道远教育科技有限公司
                    京ICP备17007624号-1
                </div>
            </div>
        </footer>
    )
}

const PromotionPopver = (props: PromotionPopverProps): JSX.Element => {
    const { list } = props
    return (
        <div className={styles['promotion-content']}>
            {list.map((item) => (
                <div
                    key={item.img}
                    className={styles['promotion-content-item']}
                >
                    <img src={item.img} alt="promotion-item" />
                    <div>{item.subLabel}</div>
                </div>
            ))}
        </div>
    )
}
export default Footer
