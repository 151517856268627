import { lazy } from 'react'
import Home from '@/views/Home/index.tsx'
import NoMatch from '@/views/Common/404.tsx'

const Knowledge = lazy(() => import('@/views/Knowledge/index.tsx'))
const Custom = lazy(() => import('@/views/Custom/index.tsx'))
const Paper = lazy(() => import('@/views/Paper/index.tsx'))
const PaperPreview = lazy(() => import('@/views/Paper/preview.tsx'))
const PaperDownload = lazy(
    () =>
        import(
            /* webpackChunkName: "paperDownload" */ '@/views/Paper/download.tsx'
        )
)
const PracticeDetail = lazy(() => import('@/views/Paper/detail.tsx'))
const Login = lazy(() => import('@/views/Login/index.tsx'))
const Register = lazy(() => import('@/views/Register/index.tsx'))
const Personal = lazy(() => import('@/views/Personal/index.tsx'))
const Publish = lazy(() => import('@/views/Publish/index.tsx'))
const MyPaper = lazy(() => import('@/views/Personal/MyPaper.tsx'))
const MyAccount = lazy(() => import('@/views/Personal/MyAccount.tsx'))
const MyClass = lazy(() => import('@/views/Personal/MyClass.tsx'))
const Homework = lazy(() => import('@/views/Personal/Homework.tsx'))
const Try = lazy(() => import('@/views/Try/index.tsx'))
const Poster = lazy(() => import('@/views/Common/Poster.tsx'))
const TrainDownload = lazy(() => import('@/views/Train/download'))
const PracticeDownload = lazy(() => import('@/views/Paper/practiceDownload') )
const CommonDownload = lazy(() => import('@/views/Paper/commonDownload') )

const routes = [
    {
        path: '/home',
        label: '首页',
        component: Home,
    },
    {
        path: '/knowledge/:type',
        label: '专题作业',
        component: Knowledge,
    },
    {
        path: '/custom',
        label: '综合测试',
        component: Custom,
    },
    {
        path: '/paper/:id',
        label: '试卷详情',
        component: Paper,
    },
    {
        path: '/preview/:id',
        label: '试卷预览',
        component: PaperPreview,
    },
    {
        path: '/download/:id',
        label: '试卷下载',
        component: PaperDownload,
    },
    {
        path: '/practiceDownload',
        label: '习题下载',
        component: PracticeDownload,
    },
    {
        path: '/commonDownload',
        label: '通用习题下载',
        component: CommonDownload
    },
    {
        path: '/practice/:id',
        label: '习题详情',
        component: PracticeDetail,
    },
    {
        path: '/try',
        label: '申请试用',
        component: Try,
    },
    {
        path: '/publish/:paperId',
        label: '试卷发布',
        component: Publish,
    },
    {
        path: '/login',
        label: '登录',
        component: Login,
    },
    {
        path: '/register',
        label: '注册',
        component: Register,
    },
    {
        path: '/poster/:id',
        label: '海报',
        component: Poster,
    },
    {
        path: '/personal',
        label: '个人中心',
        component: Personal,
        redirect: '/personal/myPaper',
        routes: [
            {
                path: '/personal/myPaper',
                label: '我的试卷',
                component: MyPaper,
            },
            {
                path: '/personal/myAccount',
                label: '我的账户',
                component: MyAccount,
            },
            {
                path: '/personal/myClass',
                label: '我的班级',
                exact: true,
                component: MyClass,
            },
            {
                path: '/personal/myClass/homework/:classId',
                label: '作业管理',
                component: Homework,
            },
        ],
    },
    {
        path: '/train/download',
        label: '高一高二同步高三综合复习下载习题pdf',
        component: TrainDownload,

    },
    {
        path: '*',
        component: NoMatch,
    },
]

export default routes
