import React from 'react'
import productImg from '@/assets/images/home/product-qr.png'
import productInfo from '@/assets/images/home/product-info.png'

import styles from './Products.module.scss'

const Product: React.FC = () => {
    return (
        <div className={styles['home-products']}>
            <div className={styles['show-box']}>
                <div className={styles['product-app']}>
                    <div className={styles['product-title']}>道远作业APP</div>
                    <div className={styles['product-intro']}>
                        学生使用App加入老师创建的班级，在线完成老师布置的作业，查看视频解析和图文解析，也可下载作业到本地，打印纸质版线下完成作业。
                    </div>
                    <div className={styles['product-details']}>
                        <img src={productImg} alt="" />
                        <div className="product-details-content">
                            <div className={styles['product-details-subtitle']}>
                                扫码下载App
                            </div>
                            <div className={styles['product-details-tips']}>
                                微信扫码下载道远作业App
                                <br />
                                或在应用宝搜索“道远作业”下载App
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles['product-introduction']}>
                    <img src={productInfo} alt="" />
                </div>
            </div>
        </div>
    )
}
export default Product
