import { AxiosRequestConfig, AxiosResponse } from 'axios'
import {
    GET_LABELS,
    GET_SUBJECT,
    SET_IS_LOGIN,
    GET_PAPERS,
    SET_USER_INFO,
    SET_SUBJECT_ID,
    GET_USER_INFO,
    CHECK_LOGIN,
    GET_APPLY,
    SET_APPLY,
    SET_LOGIN_POP,
} from '@/store/constants/index.ts'
import { Subject } from '@/@types/subjects.ts'
import { GetPapersParams, Label, UserInfo, Apply } from '@/@types/index.ts'

export interface LabelAction {
    type: string
    payload?: { request: AxiosRequestConfig } | AxiosResponse<Label[]>
}

// 获取知识点
export const getLabels = (subjectId = 1, paperType = 1): LabelAction => ({
    type: GET_LABELS,
    payload: {
        request: {
            url: '/daoyuan/api/paper_knowledge_labels',
            params: {
                cat_id: subjectId,
                paper_type: paperType,
            },
        },
    },
})

// 获取科目
export interface SubjectAction {
    type: string
    payload?: { request: AxiosRequestConfig } | AxiosResponse<Subject[]>
}

export const getSubjects = (): SubjectAction => ({
    type: GET_SUBJECT,
    payload: {
        request: {
            url: '/daoyuan/api/teacher/cats',
        },
    },
})

// 获取试卷
export interface GetPapersAction {
    type: string
    payload: { request: AxiosRequestConfig }
}
export const getPapers = (params: GetPapersParams): GetPapersAction => ({
    type: GET_PAPERS,
    payload: {
        request: {
            url: '/daoyuan/api/teacher_practice_paper',
            params: { ...params, per_page: 10 },
        },
    },
})
// 设置是否登录
export interface IsLoginAction {
    type: string
    value?: boolean
}

export const setIsLogin = (value: boolean): IsLoginAction => ({
    type: SET_IS_LOGIN,
    value,
})

export interface UserInfoAction {
    type: string
    value?: object
}

export const setUserInfo = (value: object): UserInfoAction => ({
    type: SET_USER_INFO,
    value,
})

export interface SetSubjectIdAction {
    type: string
    value: number
}
export const setSubjectId = (value: number): SetSubjectIdAction => ({
    type: SET_SUBJECT_ID,
    value,
})
// 获取用户信息
export interface GetUserInfoAction {
    type: string
    payload?: { request: AxiosRequestConfig } | AxiosResponse<UserInfo[]>
}

export const getUserInfo = (): GetUserInfoAction => ({
    type: GET_USER_INFO,
    payload: {
        request: {
            url: '/daoyuan/api/teacher',
        },
    },
})

// 获取用户信息
export interface CheckLoginAction {
    type: string
    payload?: { request: AxiosRequestConfig } | AxiosResponse<number>
}

export const checkLogin = (): CheckLoginAction => ({
    type: CHECK_LOGIN,
    payload: {
        request: {
            url: '/daoyuan/api/teacher/front_access_token',
        },
    },
})

// 获取学生申请信息
export interface GetApplysAction {
    type: string
    payload?: { request: AxiosRequestConfig } | AxiosResponse<Apply[]>
    value?: Apply[]
}

export const getApplys = (): GetApplysAction => ({
    type: GET_APPLY,
    payload: {
        request: {
            url: '/daoyuan/api/teacher/student_apply',
        },
    },
})

export const setApplys = (value: Apply[]): GetApplysAction => ({
    type: SET_APPLY,
    value,
})

export interface LoginPopAction {
    type: string
    value: boolean
}

export const setLoginPop = (value: boolean): LoginPopAction => ({
    type: SET_LOGIN_POP,
    value,
})
