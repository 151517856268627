import React from 'react'
import LoginForm from '@/views/Login/components/LoginForm.tsx'
import { useSelector, useDispatch } from 'react-redux'
import { setLoginPop } from '@/store/actions/index.ts'
import { useHistory } from 'react-router-dom'
import { UserInfo } from '@/@types/index.ts'
import { Modal } from 'antd'
import { State } from '@/store/reducers/index.ts'

const LoginPop = (): React.ReactElement => {
    const visible = useSelector((state: State) => state.isShowLoginPop)
    const dispatch = useDispatch()
    const history = useHistory()
    function handleCancel(): void {
        dispatch(setLoginPop(false))
    }
    function handleOk(): void {
        dispatch(setLoginPop(false))
    }
    function onLoginSuccess(data: UserInfo): void {
        if (!data.pwd) {
            history.push('/register?step=2')
            return
        }
        if (!data.school) {
            history.push('/register?step=3')
            return
        }
        console.log('登录成功')
        handleOk()
    }
    return (
        <Modal
            maskClosable={false}
            visible={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            width={340}
            footer={null}
        >
            <LoginForm
                style={{ width: '240px', margin: '0 auto' }}
                onSuccess={onLoginSuccess}
            />
        </Modal>
    )
}

export default LoginPop
