import React from 'react'

import flowRegister from '@/assets/images/home/flow-register.png'
import flowClass from '@/assets/images/home/flow-class.png'
import flowCheck from '@/assets/images/home/flow-check.png'
import flowAssign from '@/assets/images/home/flow-assign.png'
import flowApp from '@/assets/images/home/flow-app.png'
import flowFeedback from '@/assets/images/home/flow-feedback.png'

import styles from './Flow.module.scss'

const Flow: React.FC = () => {
    const flow = [
        {
            title: '注册账号',
            subTitle: ['学校合作，', '开通使用权限'],
            img: flowRegister,
        },
        {
            title: '建立班级',
            subTitle: ['建立班级,', '邀请学生加入'],
            img: flowClass,
        },
        {
            title: '查看试卷',
            subTitle: ['挑选题目，', '组合寒暑假作业'],
            img: flowCheck,
        },
        {
            title: '布置作业',
            subTitle: ['选择试卷，', '发布到相应班级'],
            img: flowAssign,
        },
        {
            title: '学生端APP',
            subTitle: ['学生使用APP，在线作答，', '并查看视频解析'],
            img: flowApp,
        },
        {
            title: '学情反馈',
            subTitle: ['老师准确了解', '每个学生学习情况'],
            img: flowFeedback,
        },
    ]
    const flowItems = flow.map((item, index) => {
        const subTitle = item.subTitle.map((sub) => {
            return (
                <li className={styles['flow-sub-item']} key={sub}>
                    {sub}
                </li>
            )
        })
        return (
            <li className={styles['flow-item']} key={item.title}>
                <div>
                    <div className={styles['flow-img']}>
                        <img src={item.img} alt="" />
                    </div>
                    <div className={styles['flow-title']}>
                        {index + 1}. {item.title}
                    </div>
                    <ul className={styles['flow-sub']}>{subTitle}</ul>
                </div>
            </li>
        )
    })
    return (
        <div className={`${styles['home-flow']}`}>
            <div className="show-box">
                <title>使用流程</title>
                <ul className={styles.flows}>{flowItems}</ul>
            </div>
        </div>
    )
}

export default Flow
