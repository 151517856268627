import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Tabs, Input, Button, Checkbox, message, Form, Modal } from 'antd'
import { setIsLogin, setUserInfo } from '@/store/actions/index.ts'
import request from '@/utils/request.ts'
import { UserInfo, CaptchaRes } from '@/@types/index.ts'
import { Link, useHistory } from 'react-router-dom'
import { debounce } from '@/utils/index.ts'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { MobileOutlined, LockOutlined, SafetyOutlined } from '@ant-design/icons'
import { AxiosResponse } from 'axios'
import { CONFIG, REGEXP } from '@/assets/js/global.ts'

import styles from './LoginForm.module.scss'

interface LoginInfo {
    mobile: string
    pwd?: string
    code?: string
    type: number
}
interface Props {
    onSuccess(data: UserInfo | null): void
    style?: any
}
const fn: Function = debounce(
    (value: string, setStatus: Function, setHelp: Function) => {
        if (REGEXP.mobile.test(value)) {
            setStatus('success')
            setHelp('')
        } else {
            setStatus('error')
            setHelp('手机号格式错误!')
        }
    }
)
type ValidateStatus =
    | ''
    | 'success'
    | 'warning'
    | 'error'
    | 'validating'
    | undefined

const { TabPane } = Tabs

// 腾讯云图形验证
let captcha: TencentCaptcha

let mobile: string

function sendCode(verifyRes: CaptchaRes, setTime: Function): void {
    request({
        url: '/api/sendmobilecode',
        method: 'POST',
        data: {
            mobile,
            ticket: verifyRes.ticket,
            rand_str: verifyRes.randstr,
        },
    })
        .then(() => {
            let num = 60
            message.success('验证码发送成功')
            const timer = setInterval(() => {
                setTime(num - 1)
                num -= 1
                if (num === 0) {
                    clearInterval(timer)
                    setTime(60)
                }
            }, 1000)
        })
        .catch(() => {
            message.error('验证码发送失败')
        })
}

const LoginForm: React.FC<Props> = ({ onSuccess, style }) => {
    const dispatch = useDispatch()
    const [time, setTime] = useState(60)
    const defaultInfo: LoginInfo = {
        mobile: '',
        type: 1,
        pwd: '',
        code: '',
    }
    const [loginInfo, setLoginInfo] = useState<LoginInfo>(defaultInfo)
    const [isShowProtocolTip, setIsShowProtocolTip] = useState(false)
    const [isAgree, setIsAgree] = useState(true)
    const [status, setStatus] = useState<ValidateStatus>('')
    const [help, setHelp] = useState('')
    const history = useHistory()

    function onLogin(): void {
        if (!REGEXP.mobile.test(loginInfo.mobile)) return
        interface Res {
            teacher: UserInfo
            token: string
        }
        if (!isAgree) {
            setIsShowProtocolTip(true)
            return
        }

        request({
            url: '/daoyuan/api/teacher/front_access_token',
            method: 'POST',
            data: loginInfo,
        })
            .then((res: AxiosResponse<Res>) => {
                const teacherData = res.data?.teacher
                dispatch(setIsLogin(true))
                dispatch(setUserInfo(teacherData))
                onSuccess(teacherData)
                if (!teacherData.pwd || !teacherData.school) return
                if (!teacherData.class_count) {
                    Modal.success({
                        title: `${teacherData.name}老师，欢迎您！`,
                        content:
                            '请您创建班级，并邀请学生加入，以便给学生们布置作业',
                        okText: '去建班~',
                        onOk: () => history.push('/personal/myClass'),
                    })
                }
            })
            .catch(() => {
                message.error('账号不存在或密码错误')
            })
    }
    function changeTab(key: string): void {
        setLoginInfo({
            ...loginInfo,
            type: +key,
        })
    }
    function beforeSendCode(): void {
        if (!REGEXP.mobile.test(loginInfo.mobile)) {
            message.error('请输入正确手机号码')
            return
        }
        if (status !== 'success') {
            message.error(help)
            return
        }
        // 保存当前的mobile
        mobile = loginInfo.mobile
        captcha.show()
    }

    function checkMobile(value: string): void {
        setStatus('warning')
        setHelp('手机号码格式校验中...')
        fn(value, setStatus, setHelp)
    }

    useEffect(() => {
        /* eslint-disable */
        captcha = new TencentCaptcha(
            CONFIG.TencentCaptchaAppId,
            (res: CaptchaRes) => {
                if (res.ret === 0) {
                    sendCode(res, setTime)
                }
            }
        )
        /* eslint-enable */
        return (): void => {
            captcha.destroy()
        }
    }, [])
    return (
        <div style={style} className={styles.box}>
            <Tabs
                className={styles.tab}
                defaultActiveKey="1"
                onChange={changeTab}
            >
                <TabPane tab="密码登录" key="1">
                    <Form>
                        <Form.Item
                            validateStatus={status}
                            help={<div className={styles.help}>{help}</div>}
                            hasFeedback
                        >
                            <Input
                                className={styles.input}
                                size="large"
                                value={loginInfo.mobile}
                                prefix={<MobileOutlined />}
                                onChange={(e): void => {
                                    setLoginInfo({
                                        ...loginInfo,
                                        mobile: e.target.value,
                                    })
                                    checkMobile(e.target.value)
                                }}
                                placeholder="请输入您的手机号"
                            />
                        </Form.Item>
                        <Form.Item>
                            <Input
                                className={styles.input}
                                size="large"
                                type="password"
                                value={loginInfo.pwd}
                                prefix={<LockOutlined />}
                                onChange={(e): void => {
                                    setLoginInfo({
                                        ...loginInfo,
                                        pwd: e.target.value,
                                    })
                                }}
                                placeholder="请输入密码"
                            />
                        </Form.Item>
                    </Form>
                </TabPane>
                <TabPane tab="验证码登录" key="2">
                    <Form>
                        <Form.Item
                            validateStatus={status}
                            help={<div className={styles.help}>{help}</div>}
                            hasFeedback
                        >
                            <Input
                                className={styles.input}
                                size="large"
                                value={loginInfo.mobile}
                                onChange={(e): void => {
                                    setLoginInfo({
                                        ...loginInfo,
                                        mobile: e.target.value,
                                    })
                                    checkMobile(e.target.value)
                                }}
                                prefix={<MobileOutlined />}
                                placeholder="请输入您的手机号"
                            />
                        </Form.Item>
                        <Form.Item>
                            <Input
                                className={styles.input}
                                size="large"
                                prefix={<SafetyOutlined />}
                                placeholder="验证码"
                                value={loginInfo.code}
                                onChange={(e): void => {
                                    setLoginInfo({
                                        ...loginInfo,
                                        code: e.target.value,
                                    })
                                }}
                                suffix={
                                    <Button
                                        className={styles['code-button']}
                                        type="text"
                                        onClick={beforeSendCode}
                                        disabled={time !== 60}
                                    >
                                        {time === 60
                                            ? '获取验证码'
                                            : `${time}s重新发送`}
                                    </Button>
                                }
                            />
                        </Form.Item>
                    </Form>
                </TabPane>
            </Tabs>
            <div className={styles.extra}>
                <div>
                    没有账号？
                    <Link to="/register">
                        <Button
                            type="link"
                            onClick={(): void => onSuccess(null)}
                        >
                            去注册
                        </Button>
                    </Link>
                </div>
                {/* <Link to="/register">
                    <Button type="text" onClick={onSuccess}>忘记密码</Button>
                </Link> */}
            </div>
            <Button
                className={styles['login-button']}
                size="large"
                type="primary"
                onClick={onLogin}
            >
                立即登录
            </Button>
            <div className={styles.protocol}>
                <Checkbox
                    checked={isAgree}
                    onChange={(e: CheckboxChangeEvent): void => {
                        setIsAgree(e.target.checked)
                        if (e.target.checked) setIsShowProtocolTip(false)
                        else setIsShowProtocolTip(true)
                    }}
                >
                    我已阅读并接受
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.daoyuanketang.com/announce/login-agreement"
                    >
                        <Button type="link">《道远课堂用户协议》</Button>
                    </a>
                </Checkbox>
                {isShowProtocolTip && (
                    <div className={styles['protocol-tip']}>
                        请您仔细阅读后并勾选
                    </div>
                )}
            </div>
        </div>
    )
}

export default LoginForm
