// 获取科目
export const GET_SUBJECT = 'GET_SUBJECT'
// 获取知识点
export const GET_LABELS = 'GET_LABELS'
// 设置是否登录
export const SET_IS_LOGIN = 'SET_IS_LOGIN'
// 获取试卷列表
export const GET_PAPERS = 'GET_PAPERS'
// 设置用户信息
export const SET_USER_INFO = 'SET_USER_INFO'
// 获取用户信息
export const GET_USER_INFO = 'GET_USER_INFO'
// 设置当前科目id
export const SET_SUBJECT_ID = 'SET_SUBJECT_ID'
// 检查登录
export const CHECK_LOGIN = 'CHECK_LOGIN'
// 获取学生申请
export const GET_APPLY = 'GET_APPLY'
// 设置学生申请
export const SET_APPLY = 'SET_APPLY'
// 设置显示登录弹窗
export const SET_LOGIN_POP = 'SET_LOGIN_POP'
