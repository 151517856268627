import React from 'react'
import teamLeader from '@/assets/images/home/team-leader.png'
import teamMember from '@/assets/images/home/team-member.png'
import styles from './Team.module.scss'

const Team: React.FC = () => {
    return (
        <div className={styles['home-team']}>
            <div className={`${styles['team-box']} ${styles['show-box']}`}>
                <div className={styles['team-title']}>
                    <title>清北道远课堂教研团队</title>
                    <title className={styles.sub}>
                        高考任重 道远课堂助力每一位老师教出优秀学生
                    </title>
                </div>
                <div className={styles['team-leader']}>
                    <img src={teamLeader} alt="" />
                </div>
                <div className={styles['team-member']}>
                    <img src={teamMember} alt="" />
                </div>
            </div>
        </div>
    )
}

export default Team
