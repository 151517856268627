import React, { ReactElement, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setIsLogin, setUserInfo } from '@/store/actions/index.ts'
import { NavLink, Link } from 'react-router-dom'
import { Dropdown, Menu, Button } from 'antd'
import { UserOutlined, CaretDownOutlined } from '@ant-design/icons'
import request from '@/utils/request.ts'
import { State } from '@/store/reducers/index.ts'
import logoImg from '@/assets/images/home/logo.png'
import logoText from '@/assets/images/home/logo-text.jpg'
import styles from './Header.module.scss'

const naviInfo = [
    {
        path: '/home',
        name: '首页',
    },
    {
        path: '/knowledge/topic',
        name: '专题作业',
    },
    {
        path: '/knowledge/chapter',
        name: '章节测试',
    },
    {
        path: '/custom',
        name: '综合试卷',
        children: [
            { path: '/custom?type=3', name: '高考真题' },
            { path: '/custom?type=4', name: '高考模拟' },
        ],
    },
    {
        path: '/try',
        name: '申请试用',
    },
]

interface ChildMenu {
    path: string
    name: string
}

const getChildMenu = (list: ChildMenu[]): ReactElement => {
    return (
        <Menu className={styles['child-menu']}>
            {list.map((menu) => (
                <Menu.Item key={menu.path} onClick={()=>{
                    sessionStorage.removeItem('curPage')
                }}>
                    <NavLink to={menu.path}>{menu.name}</NavLink>
                </Menu.Item>
            ))}
        </Menu>
    )
}

const Header: React.FC = () => {
    const isLogin: boolean = useSelector((state: State) => state.isLogin)
    const userName: string = useSelector((state: State) => state.userInfo.name)
    const mobile: string = useSelector((state: State) => state.userInfo.mobile)
    const [loginMenuVisible, setLoginMenuVisible] = useState(false)
    const [paperMenuVisible, setPaperMenuVisible] = useState(false)
    const dispatch = useDispatch()
    function showName(name: string): string {
        const res = `${name}`.slice(0, 4)
        return `${name}`.length > 4 ? `${res}...` : res
    }
    function logout(): void {
        request({
            url: '/daoyuan/api/teacher/front_access_token',
            method: 'DELETE',
        }).then(() => {
            dispatch(setIsLogin(false))
            dispatch(setUserInfo({}))
        })
    }
    const naviList = naviInfo.map((navi) => (
        <li key={navi.path} className="menu-item">
            {navi.children?.length ? (
                <Dropdown
                    overlay={getChildMenu(navi.children as ChildMenu[])}
                    trigger={['hover']}
                    onVisibleChange={(visible): void => {
                        setPaperMenuVisible(visible)
                    }}
                    placement="bottomCenter"
                >
                    <NavLink
                        to={navi.path}
                        activeClassName="active"
                        title={navi.name}
                    >
                        {navi.name}
                        <CaretDownOutlined
                            rotate={paperMenuVisible ? 180 : 0}
                        />
                    </NavLink>
                </Dropdown>
            ) : (
                <NavLink
                    to={navi.path}
                    activeClassName="active"
                    title={navi.name}
                >
                    {navi.name}
                </NavLink>
            )}
        </li>
    ))
    return (
        <header className={styles.header} id="header">
            <div className={styles.wrapper}>
                <div className={styles.logo}>
                    <img src={logoImg} alt="" />
                    <img
                        src={logoText}
                        alt=""
                        className={styles['logo-text']}
                    />
                </div>
                <ul className={`${styles.menu} menu`}>
                    {naviList}
                    <li className={styles.login}>
                        {isLogin ? (
                            // <Link to="/personal">{userName || mobile}</Link>
                            <Dropdown
                                overlay={
                                    <Menu className={styles['personal-menu']}>
                                        <Menu.Item key="0">
                                            <NavLink to="/personal/myPaper">
                                                我的试卷
                                            </NavLink>
                                        </Menu.Item>
                                        <Menu.Item key="1">
                                            <NavLink to="/personal/myClass">
                                                我的班级
                                            </NavLink>
                                        </Menu.Item>
                                        <Menu.Item key="2">
                                            <NavLink to="/personal/myAccount">
                                                账户信息
                                            </NavLink>
                                        </Menu.Item>
                                        <Menu.Item key="3">
                                            <NavLink to="/login">
                                                <Button
                                                    className={
                                                        styles['logout-button']
                                                    }
                                                    type="text"
                                                    onClick={logout}
                                                >
                                                    退出登录
                                                </Button>
                                            </NavLink>
                                        </Menu.Item>
                                    </Menu>
                                }
                                onVisibleChange={(visible): void => {
                                    setLoginMenuVisible(visible)
                                }}
                                placement="bottomCenter"
                            >
                                <Link
                                    className={styles.username}
                                    to="/personal"
                                >
                                    <UserOutlined />
                                    {showName(userName || mobile)}
                                    <CaretDownOutlined
                                        rotate={loginMenuVisible ? 180 : 0}
                                    />
                                </Link>
                            </Dropdown>
                        ) : (
                            <Link to="/login">
                                <UserOutlined />
                                登录/注册
                            </Link>
                        )}
                    </li>
                </ul>
            </div>
        </header>
    )
}
export default Header
