import { combineReducers } from 'redux'

import {
    labelsReducer,
    subjectReducer,
    papersReducer,
    PapersResponse,
    isLogin,
    userInfo,
    setSubjectIdReducer,
    getApplysReducer,
    isShowLoginPopReducer,
} from '@/store/reducers/global.ts'
import { Label, UserInfo, Apply } from '@/@types/index.ts'

import { Subject } from '@/@types/subjects.ts'

export interface State {
    labels: Label[]
    subjects: Subject[]
    subjectId: number
    papers: PapersResponse
    isLogin: boolean
    userInfo: UserInfo
    applys: Apply[]
    isShowLoginPop: boolean
}

const App = combineReducers({
    labels: labelsReducer,
    subjects: subjectReducer,
    subjectId: setSubjectIdReducer,
    papers: papersReducer,
    isLogin,
    userInfo,
    applys: getApplysReducer,
    isShowLoginPop: isShowLoginPopReducer,
})

export default App
